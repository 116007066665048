import React from 'react';

import './Loading.scss';

interface IProps {
  position?: string;
}

const Loading = (props: IProps) => {
  const {
    position,
  } = props;

  const classes = position ? `loading loading-${position}` : 'loading';

  return (
    <div className={classes}>
      <div className='loading-element'><div /><div/><div/><div/></div>
    </div>
  );
};

export default Loading;
