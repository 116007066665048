import { connect } from 'react-redux';
import { flow } from 'lodash';

import EditUser from './EditUser';
import {
  closeUser,
  updateFirstName,
  updateLastName,
  updateCompany,
  updateEmail,
  updateMaxPlayers,
  updateExpiration,
  updateMusic,
  updateClient,
  updateActive,
  updateNewPassword,
  updateRepeatPassword,
  updateNewPasswordAdmin,
  updateRepeatPasswordAdmin,
  updateUserType,
  requestEditUser,
  requestSubscriptionsData,
  requestPartners,
  updateMaxSubscriptions,
  updateDBHost,
  updateDBPort,
  updateDBDriver,
  updateDBPassword,
  updateDBUsername,
  updateDBDatabase,
  updateMusicWL,
  updateAdministrator,
} from 'actions/editUser';
import { IState } from 'reducers';
import { injectIntl } from 'react-intl';

const mapStateToProps = (state: IState) => ({
  loggedUserType: state.data.auth.usertype_id,
  user: state.data.editUser.user,
  original_usertype_id: state.data.editUser.original_usertype_id,
  subscriptionsData: state.data.editUser.subscriptions_data,
  partners: state.data.editUser.partners,
  disabled: state.data.auth.invalid_subscriptions_number,
  error: state.data.editUser.error,
  loading: state.data.editUser.loading,
});

const mapDispatchToProps = {
  closeUser,
  updateFirstName,
  updateLastName,
  updateCompany,
  updateEmail,
  updateMaxPlayers,
  updateExpiration,
  updateMusic,
  updateClient,
  updateActive,
  updateNewPassword,
  updateRepeatPassword,
  updateNewPasswordAdmin,
  updateRepeatPasswordAdmin,
  updateUserType,
  requestEditUser,
  requestSubscriptionsData,
  requestPartners,
  updateMaxSubscriptions,
  updateDBHost,
  updateDBPort,
  updateDBDriver,
  updateDBPassword,
  updateDBUsername,
  updateDBDatabase,
  updateMusicWL,
  updateAdministrator,
};

const connectDecorator = connect(mapStateToProps, mapDispatchToProps);

export default flow([
  injectIntl,
  connectDecorator,
])(EditUser);
