import React from 'react';

import './GridItem.scss';
//import { IPuntaje } from 'actions/searchCases';
import Rating from 'react-rating';

interface IAction {
  name: string;
  icon: string;
  color: string;
  onClick: () => void;
}


export interface IGridItem {
  content: Array<string>;
  ratingIndex?: number;
  actions?: IAction[];
}

const GridItem = (props: IGridItem) => {
  const {
    content,
    actions,
    ratingIndex,
  } = props;

  const itemContent = content.map((value, i) => {
    if (ratingIndex && i === ratingIndex) {
      return (
        <td key={i} className='rating-cell'>
          <Rating
            emptySymbol='fa fa-star-o fa-2x'
            fullSymbol='fa fa-star fa-2x'
            // initialRating={value}
            readonly={true}
          />
        </td>
      );
    } else {
      return <td key={i}>{value}</td>;
    }
  });

  const actionsInnerContent = (actions) ? actions.map((action, i) => (
      <div className={`item-action bg-${action.color}`} onClick={action.onClick} key={i} title={action.name}>
        <i className={`fa ${action.icon}`} />
      </div>
    )
  ) : null;

  const actionsContent  = actionsInnerContent ? <td className='item-actions'>{actionsInnerContent}</td> : null;

  return (
    <tr>
      {itemContent}
      {actionsContent}
    </tr>
  );
};

export default GridItem;
