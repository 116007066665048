function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export default strEnum([
  'GO_TO_HOME',
  'GO_TO_USERS',
  'GO_TO_NEW_USER',
]);
