import { connect } from 'react-redux';
import { flow } from 'lodash';
import {
  setLang,
} from 'actions/auth';
import {
  requestAccessToken,
} from 'actions/auth';

import Login from './Login';
import { injectIntl } from 'react-intl';

const mapDispatchToProps = {
  requestAccessToken,
  setLang,
};

const mapStateToProps = (state: any) => ({
  loading: state.data.auth.loading,
  error: state.data.auth.error,
  lang: state.data.auth.lang,
});

const connectDecorator = connect(mapStateToProps, mapDispatchToProps);

export default flow([
  injectIntl,
  connectDecorator,
])(Login);

