import React from 'react';

import './GridFilters.scss';
import Button from 'components/Button2';

interface IGridFilters {
  searchValue: string;
  pageLength: number;
  pageLengthOptions: number[];
  onPageLengthChange: (e: React.FormEvent<HTMLSelectElement>) => void;
  onSearchChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSearchSubmit?: () => void;
}

const GridFilters = (props: IGridFilters) => {
  const {
    searchValue,
    pageLength,
    pageLengthOptions,
    onSearchChange,
    onPageLengthChange,
    onSearchSubmit,
  } = props;

  const pageLengthSelectOptions =
    pageLengthOptions.map((option, i) => <option value={option} key={i}>{option}</option>);

  const search = searchValue ? (
    <div className='col-sm-12 col-md-6 nopadding-right'>
      <div id='tabla_casos_filter' className='dataTables_filter'>
        <label>
          Buscar:
          <input
            type='search'
            className='form-control form-control-sm'
            placeholder=''
            aria-controls='tabla_casos'
            value={searchValue}
            onChange={onSearchChange}
          />
        </label>
        <div className='margin-left-10 margin-top-2'>
          <Button
            external={false}
            onClick={onSearchSubmit}
            text=''
            icon='fa-search'
            color='primary'
          />
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div className='col-md-12 nopadding'>
      <div className='col-sm-12 col-md-6 nopadding-left'>
        <div className='dataTables_length nopadding-left' id='tabla_casos_length'>
          <label>Mostrar
            <select
              name='tabla_casos_length'
              aria-controls='tabla_casos'
              className='custom-select custom-select-sm form-control form-control-sm'
              value={pageLength}
              onChange={onPageLengthChange}
            >
              {pageLengthSelectOptions}
            </select> casos
          </label>
        </div>
      </div>

      {search}
    </div>
  );
};

export default GridFilters;
