import { connect } from 'react-redux';
import { flow } from 'lodash';

import Home from './Home';
import {
  requestUsers,
} from 'actions/users';
import {
  showUser,
} from 'actions/editUser';
import withLogin from 'containers/withLogin';
import { IState } from 'reducers';
import { injectIntl } from 'react-intl';

const mapStateToProps = (state: IState) => ({
  usersState: state.data.users,
  showEditUser: state.data.editUser.show,
  selectedId: state.data.editUser.user.id,
  loggedUserType: state.data.auth.usertype_id,
});

const mapDispatchToProps = {
  requestUsers,
  showUser,
};

const connectDecorator = connect(mapStateToProps, mapDispatchToProps);

export default flow([
  withLogin(),
  injectIntl,
  connectDecorator,
])(Home);
