
import { ofType, StateObservable, ActionsObservable } from 'redux-observable';
import { takeUntil, take, mergeMapTo, merge, map, catchError } from 'rxjs/operators';
import { of, defer } from 'rxjs';
import { AjaxRequest, ajax, AjaxError } from 'rxjs/ajax';
import { IState } from '../reducers';
import { Action } from 'redux';
import { ActionCreator } from 'typescript-fsa';

import ActionTypes from '../constants/ActionTypes/auth';
import { requestRefreshToken } from '../actions/auth';
import { BASE_URL , CMS_URL} from '../constants/auth';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface IRequestWithAuth {
  action$: ActionsObservable<Action>;
  store: StateObservable<IState>;
  url: string;
  method: HttpMethod;
  successAction: ActionCreator<any>;
  successActionParams?: string | {};
  failureAction: ActionCreator<any>;
  params?: string;
  body?: {};
  noV1?: boolean;
}

interface IRequest {
  url: string;
  method: HttpMethod;
  params?: string;
  body?: {};
  noV1?: boolean;
}

export const isError401 = (error: AjaxError) => {
  return (error.response && error.response.code === 401);
};

const checkRequesRefresh = (store: StateObservable<IState>) => {
  const loading = store.value.data.auth.loading;
  if (!loading) {
    return requestRefreshToken(undefined);
  } else {
    return { type: 'ALREADY_WAITING_FOR_NEW_ACCESS_TOKEN', payload: undefined };
  }
};

const retryRequest = (retryParams: {
  action$: ActionsObservable<Action>,
  store: StateObservable<IState>,
  error: AjaxError,
  source: ActionsObservable<Action>,
  failureAction: ActionCreator<any>
}) => {
  const {
    action$,
    store,
    error,
    source,
    failureAction,
  } = retryParams;
  if (isError401(error)) {
    return action$.pipe(
      ofType(ActionTypes.SUCCESS_REFRESH_TOKEN),
      takeUntil(action$.ofType(ActionTypes.FAILURE_REFRESH_TOKEN)),
      take(1),
      mergeMapTo(source),
      merge(
        of(checkRequesRefresh(store))
      ),
    );
  } else {
    return of(failureAction(error));
  }
};

export const http = (httpParams: {
  store: StateObservable<IState>,
  url: string,
  method: HttpMethod,
  params?: string,
  body?: {},
  noV1?: boolean
}) => {
  const {
    store,
    url,
    method,
    params,
    body,
    noV1,
  } = httpParams;
  const baseUrl = noV1 ? BASE_URL.replace('/v1', '') : BASE_URL;
  const urlComplete = method === 'GET' && params ? `${baseUrl}${url}/${params}` : `${baseUrl}${url}`;
  const access_token = store.value.data.auth.access_token;
  const options: AjaxRequest = {
    method,
    url: urlComplete,
    headers: {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    },
  };

  if (method === 'POST' || method === 'PUT') {
    options.body = {
      ...body,
    };
  }

  return ajax(options);
};

export const requestWithAuth = (req: IRequestWithAuth) => {
  const {
    action$,
    store,
    method,
    url,
    params,
    body,
    successAction,
    successActionParams,
    failureAction,
    noV1,
  } = req;
  return defer(() => {
    return http({ store, url, method, params, body, noV1 });
  })
  .pipe(
    map((response: any) =>
      successActionParams ? successAction(successActionParams) : successAction(response.response)),
    catchError((error: AjaxError, source: any) => {
      return retryRequest({ action$, store, error, source, failureAction });
    })
  );
};

export const request = (req: IRequest) => {
  const {
    url,
    method,
    params,
    body,
    noV1,
  } = req;
  const baseUrl = noV1 ? BASE_URL.replace('/v1', '') : BASE_URL;
  const urlComplete = method === 'GET' && params ? `${baseUrl}${url}/${params}` : `${baseUrl}${url}`;
  const options: AjaxRequest = {
    method,
    url: urlComplete,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (method === 'POST' || method === 'PUT') {
    options.body = {
      ...body,
    };
  }

  return ajax(options);
};

export const requestCms = (req: IRequest) => {
  const {
    url,
    method,
    params,
    body,
    
  } = req;
  const baseUrl = CMS_URL;
  const urlComplete = method === 'GET' && params ? `${baseUrl}${url}/${params}` : `${baseUrl}${url}`;
  const options: AjaxRequest = {
    method,
    url: urlComplete,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (method === 'POST' || method === 'PUT') {
    options.body = {
      ...body,
    };
  }

  return ajax(options);
};
