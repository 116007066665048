import { combineEpics } from 'redux-observable';

import * as RouteEpics from './routes';
import * as AuthEpics from './auth';
import * as UsersEpics from './users';
import * as EditUserEpics from './editUser';

export default combineEpics(
  ...Object.values(RouteEpics),
  ...Object.values(AuthEpics),
  ...Object.values(UsersEpics),
  ...Object.values(EditUserEpics),
);
