
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';
import epics from './epics';
import { BASENAME } from 'constants/auth';

export const history = createBrowserHistory({ basename: BASENAME });

const epicMiddleware = createEpicMiddleware();

const enhancers = composeWithDevTools(
  applyMiddleware(epicMiddleware, routerMiddleware(history))
);

export function configureStore() {
  const store = createStore(rootReducer(history), enhancers);
  epicMiddleware.run(epics);
  return store;
}
