function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export default strEnum([
  'REQUEST_ACCESS_TOKEN',
  'SUCCESS_ACCESS_TOKEN',
  'FAILURE_ACCESS_TOKEN',

  'REQUEST_REFRESH_TOKEN',
  'SUCCESS_REFRESH_TOKEN',
  'FAILURE_REFRESH_TOKEN',

  'REQUEST_PROFILE',
  'SUCCESS_PROFILE',
  'FAILURE_PROFILE',

  'REQUEST_LOGOUT',
  'SUCCESS_LOGOUT',
  'FAILURE_LOGOUT',

  'SET_LANG',
]);
