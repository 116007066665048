import React from 'react';

import './Button.scss';

interface IProps {
  color: 'primary' | 'warning' | 'success' | 'info' | 'danger' | 'default';
  text: string;
  external: boolean;
  icon?: string;
  href?: string;
  disabled?: boolean;
  extraClasses?: string;
  size?: 'small' | 'big';
  onClick?: () => void;
}

const Button = (props: IProps) => {
  const {
    href,
    color,
    icon,
    text,
    external,
    size,
    onClick,
    disabled,
    extraClasses,
  } = props;

  const iconContent = icon ? (<i className={`fa ${icon}`} aria-hidden='true' />) : null;
  const onClickFn = onClick ? onClick : () => { /* */ };
  const disabledClass = disabled ? 'btn-disabled' : '';
  const sizeClass = size ? size : '';
  const extraClass = extraClasses ? extraClasses : '';

  const content = external ? (
    <a href={href} target='_blank' className={`btn btn-nuv btn-${color} ${extraClass}`}  rel='noopener noreferrer'>
      {iconContent}
      {text}
    </a>
  ) : (
    <div className={`btn btn-nuv btn-${color} ${disabledClass} btn-${sizeClass} ${extraClass}`} onClick={onClickFn}>
      {iconContent}
      {text}
    </div>
  );
  return content;
};

export default Button;
