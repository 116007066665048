import actionCreatorFactory from 'typescript-fsa';
import ActionTypes from 'constants/ActionTypes/users';
import { ErrorObserver } from 'rxjs';

const actionCreator = actionCreatorFactory();

export enum IUserType {
  end_user = 'null',
  root = 1,
  white_label = 2,
  partner = 3,
}

export enum IUsersStatusFilter {
  all = '',
  active = 1,
  inactive = 0,
}

export enum IUsersClientFilter {
  all = '',
  client = 1,
  no_client = 0,
}

export interface IUserList {
  id: number;
  max_players: number;
  active: 0 | 1;
  email: string;
  last_name: string;
  first_name: string;
  company: string;
  count_players: number;
  trial_ends_at: string;
  last_login: string;
  music: 0 | 1;
  client: 0 | 1;
  usertype_id: IUserType;
  administrator_id: number;
}

export const requestUsers = actionCreator<undefined>(ActionTypes.REQUEST_USERS);
export const requestNewUser = actionCreator<undefined>(ActionTypes.REQUEST_NEW_USER);
export const successUsers = actionCreator<IUserList>(ActionTypes.SUCCESS_USERS);
export const failureUsers = actionCreator<ErrorObserver<any>>(ActionTypes.FAILURE_USERS);

export const filterUsersText = actionCreator<string>(ActionTypes.FILTER_USERS_TEXT);
export const filterUsersStatus = actionCreator<IUsersStatusFilter>(ActionTypes.FILTER_USERS_STATUS);
export const filterUsersClient = actionCreator<IUsersClientFilter>(ActionTypes.FILTER_USERS_CLIENT);

export const pageLengthChange = actionCreator<number>(ActionTypes.USERS_PAGE_LENGTH_CHANGE);
export const pageChange = actionCreator<number>(ActionTypes.USERS_PAGE_CHANGE);
export const columnChange = actionCreator<string>(ActionTypes.USERS_COLUMN_CHANGE);
