import React from 'react';

import { ISubscriptionsData } from 'actions/editUser';
import { IUserType } from 'actions/users';
import Dropdown, { IDropdownOption } from 'components/Dropdown/Dropdown';
import { musicOptions } from '../EditUser';
import { IntlFormatters } from 'react-intl';

interface IProps {
  intl: IntlFormatters;
  loggedUserType: IUserType;
  usertype_id: IUserType;
  subscriptionsData: ISubscriptionsData;
  disabled?: boolean;
  updateMaxSubscriptions: (val: number) => void;
  updateDBHost: (val: string) => void;
  updateDBDatabase: (val: string) => void;
  updateDBPort: (val: string) => void;
  updateDBDriver: (val: string) => void;
  updateDBUsername: (val: string) => void;
  updateDBPassword: (val: string) => void;
  updateMusicWL: (val: boolean) => void;
}

const WhiteLabelData = (props: IProps) => {
  const {
    loggedUserType,
    usertype_id,
    updateMaxSubscriptions,
    subscriptionsData,
    updateDBHost,
    updateDBPort,
    updateDBDatabase,
    updateDBDriver,
    updateDBUsername,
    updateDBPassword,
    updateMusicWL,
    disabled,
  } = props;

  const translatedMusicOptions = musicOptions.map((option) => {
    option.label = props.intl.formatMessage({ id: option.id });
    return option;
  });

  const handleMaxSubscriptions = (e: React.FormEvent<HTMLInputElement>) => {
    updateMaxSubscriptions(+e.currentTarget.value);
  };

  const handleDBHost = (e: React.FormEvent<HTMLInputElement>) => {
    updateDBHost(e.currentTarget.value);
  };

  const handleDBDatabase = (e: React.FormEvent<HTMLInputElement>) => {
    updateDBDatabase(e.currentTarget.value);
  };

  const handleDBPort = (e: React.FormEvent<HTMLInputElement>) => {
    updateDBPort(e.currentTarget.value);
  };

  const handleDBDriver = (e: React.FormEvent<HTMLInputElement>) => {
    updateDBDriver(e.currentTarget.value);
  };

  const handleDBUsername = (e: React.FormEvent<HTMLInputElement>) => {
    updateDBUsername(e.currentTarget.value);
  };

  const handleDBPassword = (e: React.FormEvent<HTMLInputElement>) => {
    updateDBPassword(e.currentTarget.value);
  };

  const handleMusicWLChange = (option: IDropdownOption) => {
    if (typeof option.value === 'boolean') {
      updateMusicWL(option.value);
    }
  };

  if (usertype_id !== IUserType.partner && usertype_id !== IUserType.white_label) {
    return null;
  }

  let whiteLabelContent = null;

  if (usertype_id === IUserType.white_label && loggedUserType === IUserType.root) {
    whiteLabelContent = (
      <div className='panel-body'>
        <div className='panel-option'>
          <label>DB Host</label>
          <input
            type='text'
            value={subscriptionsData.db_host}
            onChange={handleDBHost}
            disabled={disabled}
          />
        </div>

        <div className='panel-option'>
          <label>DB Port</label>
          <input
            type='text'
            value={subscriptionsData.db_port}
            onChange={handleDBPort}
            disabled={disabled}
          />
        </div>

      <div className='panel-option'>
        <label>DB Driver</label>
        <input
          type='text'
          value={subscriptionsData.db_driver}
          onChange={handleDBDriver}
          disabled={disabled}
        />
      </div>


        <div className='panel-option'>
          <label>DB Database</label>
          <input
            type='text'
            value={subscriptionsData.db_database}
            onChange={handleDBDatabase}
            disabled={disabled}
          />
        </div>

        <div className='panel-option'>
          <label>DB Username</label>
          <input
            type='text'
            value={subscriptionsData.db_username}
            onChange={handleDBUsername}
            disabled={disabled}
          />
        </div>

        <div className='panel-option'>
          <label>DB Password</label>
          <input
            type='text'
            value={subscriptionsData.db_password}
            onChange={handleDBPassword}
            disabled={disabled}
          />
        </div>

        <div className='panel-option'>
          <Dropdown
            options={translatedMusicOptions}
            label={props.intl.formatMessage({ id: 'music-clients' })}
            selected={subscriptionsData.music_wl}
            onSelect={handleMusicWLChange}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className='panel'>
        <div className='panel-header'>
          <h4>{props.intl.formatMessage({ id: 'white-label-config' })}</h4>
        </div>

        <div className='panel-body'>
          <div className='panel-option'>
            <label>Máx. Suscripciones</label>
            <input
              type='number'
              min='0'
              value={subscriptionsData.max_subscriptions}
              onChange={handleMaxSubscriptions}
            />
          </div>
        </div>

        {whiteLabelContent}
      </div>

      <hr/>
    </React.Fragment>
  );
};

export default WhiteLabelData;
