import { IUserType } from 'actions/users';

const userTypeLabel = (user: IUserType) => {
  switch (user) {
    case IUserType.end_user:
      return 'Usuario final';
    case IUserType.partner:
      return 'Partner';
    case IUserType.root:
      return 'Root';
    case IUserType.white_label:
      return 'Marca blanca';
    default:
      return '-';
  }
};

export default userTypeLabel;
