function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export default strEnum([
  'SHOW_USER',
  'CLOSE_USER',

  'UPDATE_FIRST_NAME',
  'UPDATE_LAST_NAME',
  'UPDATE_COMPANY',
  'UPDATE_EMAIL',
  'UPDATE_ACTIVE',
  'UPDATE_MUSIC',
  'UPDATE_CLIENT',
  'UPDATE_EXPIRATION',
  'UPDATE_MAX_PLAYERS',
  'UPDATE_USER_TYPE',
  'UPDATE_ADMINISTRATOR',

  'UPDATE_NEW_PASSWORD',
  'UPDATE_REPEAT_PASSWORD',

  'UPDATE_NEW_PASSWORD_ADMIN',
  'UPDATE_REPEAT_PASSWORD_ADMIN',

  'REQUEST_EDIT_USER',
  'SUCCESS_EDIT_USER',
  'FAILURE_EDIT_USER',

  'REQUEST_SUBSCRIPTIONS_DATA',
  'SUCCESS_SUBSCRIPTIONS_DATA',
  'FAILURE_SUBSCRIPTIONS_DATA',

  'REQUEST_PARTNERS',
  'SUCCESS_PARTNERS',
  'FAILURE_PARTNERS',

  'UPDATE_MAX_SUBSCRIPTIONS',
  'UPDATE_DB_HOST',
  'UPDATE_DB_PORT',
  'UPDATE_DB_DRIVER',
  'UPDATE_DB_USERNAME',
  'UPDATE_DB_PASSWORD',
  'UPDATE_DB_DATABASE',
  'UPDATE_MUSIC_WL',
]);
