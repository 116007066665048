import React from 'react';
import GridHeader from './GridHeader';
import { IGridHeaderItem } from './GridHeader/GridHeader';
import GridItem from './GridItem';
import { IGridItem } from './GridItem/GridItem';
import GridFilters from './GridFilters';
import GridPaginate from './GridPaginate';
import './Grid.scss';

interface IGridProps {
  headers: IGridHeaderItem[];
  items: IGridItem[];
  totalPages?: number;
  sortable?: boolean;
  sortedBy?: string;
  sortOrder?: boolean;
  filterable: boolean;
  paginate: boolean;
  pageLength?: number;
  pageLengthOptions?: number[];
  currentPage?: number;
  searchValue?: string;
  onSearchChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onPageLengthChange?: (e: React.FormEvent<HTMLSelectElement>) => void;
  onPageChange?: (selectedItem: { selected: number; }) => void;
  onSortChange?: (sort: string) => void;
  onSearchSubmit?: () => void;
}

const Grid = (props: IGridProps) => {
  const {
    headers,
    items,
    searchValue,
    pageLength,
    totalPages,
    currentPage,
    pageLengthOptions,
    onPageLengthChange,
    onSearchChange,
    onPageChange,
    filterable,
    paginate,
    sortable,
    sortOrder,
    sortedBy,
    onSortChange,
    onSearchSubmit,
  } = props;

  const itemsContent = items.map((item, i) =>
    <GridItem content={item.content} actions={item.actions} key={i} ratingIndex={item.ratingIndex} />);
  
  const filterableContent = (
      filterable &&
      currentPage &&
      pageLength &&
      totalPages &&
      pageLengthOptions &&
      onPageLengthChange
    ) ? (
      <GridFilters
        searchValue={searchValue || ''}
        pageLengthOptions={pageLengthOptions}
        pageLength={pageLength}
        onPageLengthChange={onPageLengthChange}
        onSearchChange={onSearchChange}
        onSearchSubmit={onSearchSubmit}
      />
    ) : null;

  const pLength = pageLength ? pageLength : 1;
  const cPage = currentPage ? currentPage : 1;
  const paginateContent = (paginate && onPageChange) ? (
    <GridPaginate
      currentPage={cPage}
      totalPages={totalPages || 0}
      totalItems={items.length}
      pageLength={pLength}
      onPageChange={onPageChange}
    />
  ) : null;

  return (
    <React.Fragment>
      {filterableContent}
      <table className='table table-striped' id='grid'>
        <GridHeader
          headers={headers}
          sortable={sortable}
          sortOrder={sortOrder}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
        />
        <tbody className='list'>
          {itemsContent}
        </tbody>
      </table>
      {paginateContent}
    </React.Fragment>
  );
};

export default Grid;
