import React, { useEffect } from 'react';

import SimpleBar from 'simplebar-react';
import DatePicker from 'react-datepicker';

import Button from 'components/Button';
import { IEditUser, ISubscriptionsData, IAdministratorId, IPartner } from 'actions/editUser';

import './EditUser.scss';
import Dropdown, { IDropdownOption, IDropdownOptionTranslatable } from 'components/Dropdown/Dropdown';
import { validateUser } from 'utils/validateEditUser';
import { IUserType } from 'actions/users';
import WhiteLabelData from './WhiteLabelData';
import Password from './Password';
import PasswordAdmin from './Password/PasswordAdmin';
import { IntlFormatters } from 'react-intl';
import userTypesOptions from 'utils/formatUserTypes';
import Loading from 'components/Loading';


interface IProps {
  intl: IntlFormatters;
  user: IEditUser;
  loggedUserType: IUserType;
  original_usertype_id: IUserType;
  subscriptionsData: ISubscriptionsData;
  partners: IPartner[];
  disabled: boolean;
  error: string;
  loading: boolean;
  closeUser: () => void;
  updateFirstName: (val: string) => void;
  updateLastName: (val: string) => void;
  updateCompany: (val: string) => void;
  updateEmail: (val: string) => void;
  updateMaxPlayers: (val: number) => void;
  updateExpiration: (val: string) => void;
  updateMusic: (val: boolean) => void;
  updateClient: (val: boolean) => void;
  updateActive: (val: boolean) => void;
  updateNewPassword: (val: string) => void;
  updateRepeatPassword: (val: string) => void;
  updateNewPasswordAdmin: (val: string) => void;
  updateRepeatPasswordAdmin: (val: string) => void;
  updateUserType: (val: IUserType) => void;
  updateAdministrator: (val: IAdministratorId) => void;
  requestEditUser: () => void;
  requestSubscriptionsData: (val: number) => void;
  requestPartners: () => void;
  updateMaxSubscriptions: (val: number) => void;
  updateDBHost: (val: string) => void;
  updateDBPort: (val: string) => void;
  updateDBDriver: (val: string) => void;
  updateDBDatabase: (val: string) => void;
  updateDBUsername: (val: string) => void;
  updateDBPassword: (val: string) => void;
  updateMusicWL: (val: boolean) => void;
}

export const musicOptions: IDropdownOptionTranslatable[] = [
  { value: true, label: 'Activada', id: 'music-active' },
  { value: false, label: 'Desactivada', id: 'music-inactive' }
];

export const clientOptions: IDropdownOptionTranslatable[] = [
  { value: true, label: 'Si', id: 'client-active' },
  { value: false, label: 'No', id: 'client-inactive' }
];

const EditUser = (props: IProps) => {
  const {
    id,
    first_name,
    last_name,
    company,
    email,
    max_players,
    expiration,
    last_login,
    count_players,
    new_password,
    repeat_password,
    new_password_admin,
    repeat_password_admin,
    active,
    music,
    client,
    usertype_id,
    administrator_id,
  } = props.user;

  const {
    updateFirstName,
    updateLastName,
    updateCompany,
    updateEmail,
    updateMaxPlayers,
    updateExpiration,
    updateNewPassword,
    updateRepeatPassword,
    updateNewPasswordAdmin,
    updateRepeatPasswordAdmin,
    updateMaxSubscriptions,
    updateActive,
    updateMusic,
    updateClient,
    updateUserType,
    updateAdministrator,
    requestEditUser,
    subscriptionsData,
    requestSubscriptionsData,
    requestPartners,
    updateDBHost,
    updateDBPort,
    updateDBDriver,
    updateDBPassword,
    updateDBUsername,
    updateDBDatabase,
    updateMusicWL,
    partners,
    loggedUserType,
    original_usertype_id,
    disabled,
    error,
    loading,
  } = props;

  useEffect(() => {
    if (original_usertype_id === IUserType.white_label || original_usertype_id === IUserType.partner) {
      requestSubscriptionsData(id);
    }
  }, [requestSubscriptionsData, id, original_usertype_id]);

  useEffect(() => {
    requestPartners();
  }, [requestPartners]);

  if (id === 0) {
    return null;
  }

  const activeOptions: IDropdownOptionTranslatable[] = [
    { value: true, label: 'Activo', id: 'active' },
    { value: false, label: 'Inactivo', id: 'inactive' }
  ];

  const partnersOptions = partners.map((option) => {
    return {
      value: option.id,
      label: `${option.first_name} ${option.last_name}`,
    };
  });

  const handleFirstNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateFirstName(e.currentTarget.value);
  };

  const handleLastNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateLastName(e.currentTarget.value);
  };

  const handleCompanyChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateCompany(e.currentTarget.value);
  };

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateEmail(e.currentTarget.value);
  };

  const handleMaxPlayersChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateMaxPlayers(+e.currentTarget.value);
  };

  const handleExpirationChange = (date: Date) => {
    updateExpiration(date.toString());
  };

  const handleActiveChange = (option: IDropdownOption) => {
    if (typeof option.value === 'boolean') {
      updateActive(option.value);
    }
  };

  const handleMusicChange = (option: IDropdownOption) => {
    if (typeof option.value === 'boolean') {
      updateMusic(option.value);
    }
  };

  const handleClientChange = (option: IDropdownOption) => {
    if (typeof option.value === 'boolean') {
      updateClient(option.value);
    }
  };

  const handleUserTypeChange = (option: IDropdownOption) => {
    updateUserType(option.value);
  };

  const handleAdministrator = (option: IDropdownOption) => {
    updateAdministrator(option.value);
  };

  const handleSave = () => {
    const validUser = validateUser(props.user);
    if (validUser.valid) {
      requestEditUser();
    } else {
      alert(validUser.errors[0]);
    }
  };

  let administrator_content = loggedUserType === IUserType.root && usertype_id === IUserType.end_user ? (
    <div className='panel-option'>
      <Dropdown
        options={partnersOptions}
        label='Gestionado por (partner)'
        selected={administrator_id ? administrator_id : 0}
        onSelect={handleAdministrator}
      />
    </div>
  ) : null;
  administrator_content = null;

  const translatedMusicOptions = musicOptions.map((option) => {
    option.label = props.intl.formatMessage({ id: option.id });
    return option;
  });
  const translatedClientOptions = clientOptions.map((option) => {
    option.label = props.intl.formatMessage({ id: option.id });
    return option;
  });

  const translatedActiveOptions = activeOptions.map((option) => {
    option.label = props.intl.formatMessage({ id: option.id });
    return option;
  });

  const translatedUserTypes = userTypesOptions.map((option) => {
    option.label = props.intl.formatMessage({ id: option.id });
    return option;
  });

  const user_type_content = loggedUserType === IUserType.root ? (
    <div className='panel-option'>
      <Dropdown
        options={translatedUserTypes}
        label={props.intl.formatMessage({ id: 'user-type' })}
        selected={usertype_id}
        onSelect={handleUserTypeChange}
        disabled={disabled}
      />
    </div>
  ) : null;

  const loading_content = loading ? <Loading position='fixed' /> : null;

  const errorMessage = error ? props.intl.formatMessage({ id: error }) : '';

  return (
    <React.Fragment>
      {loading_content}
      <div className='edit-user'>
        <div className='edit-user-header'>
          <h3>
            <span> {props.intl.formatMessage({ id: 'editing' })}: {first_name} {last_name}</span>
            <div className='close-icon' onClick={props.closeUser} />
          </h3>
        </div>

        <SimpleBar style={{ height: '100%' }}>
          <div className='panel'>
            <div className='panel-header'>
              <h4>{props.intl.formatMessage({ id: 'basic-info' })}</h4>
            </div>

            <div className='panel-body'>
              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'col-name' })}</label>
                <input type='text' value={first_name} onChange={handleFirstNameChange} disabled={disabled} />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'col-last-name' })}</label>
                <input type='text' value={last_name} onChange={handleLastNameChange} disabled={disabled} />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'col-company' })}</label>
                <input type='text' value={company} onChange={handleCompanyChange} disabled={disabled} />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'col-email' })}</label>
                <input type='text' value={email} onChange={handleEmailChange} disabled={disabled} />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'col-last-login' })}</label>
                <input type='text' readOnly={true} value={last_login || ''} disabled={true} />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'expiration-date' })}</label>
                <DatePicker
                  selected={new Date(expiration)}
                  onChange={handleExpirationChange}
                  disabled={disabled}
                />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'max-players' })}</label>
                <input type='number' min='0' value={max_players} onChange={handleMaxPlayersChange} />
              </div>

              <div className='panel-option'>
                <label>{props.intl.formatMessage({ id: 'col-players-in-use' })}</label>
                <input type='text' readOnly={true} value={count_players} disabled={true} />
              </div>

              <div className='panel-option'>
                <Dropdown
                  options={translatedActiveOptions}
                  label={props.intl.formatMessage({ id: 'status' })}
                  selected={active}
                  onSelect={handleActiveChange}
                />
              </div>

              <div className='panel-option'>
                <Dropdown
                  options={translatedMusicOptions}
                  label={props.intl.formatMessage({ id: 'music' })}
                  selected={music}
                  onSelect={handleMusicChange}
                  disabled={disabled}
                />
              </div>

              <div className='panel-option'>
                <Dropdown
                  options={translatedClientOptions}
                  label={props.intl.formatMessage({ id: 'client' })}
                  selected={client}
                  onSelect={handleClientChange}
                 
                />
              </div>

              {user_type_content}

              {administrator_content}
            </div>
          </div>

          <hr/>

          <WhiteLabelData
            loggedUserType={loggedUserType}
            usertype_id={usertype_id}
            subscriptionsData={subscriptionsData}
            updateMaxSubscriptions={updateMaxSubscriptions}
            updateDBHost={updateDBHost}
            updateDBPort={updateDBPort}
            updateDBDriver={updateDBDriver}
            updateDBDatabase={updateDBDatabase}
            updateDBPassword={updateDBPassword}
            updateDBUsername={updateDBUsername}
            updateMusicWL={updateMusicWL}
            disabled={disabled}
          />

          <div className='panel'>
            <Password
              new_password={new_password}
              repeat_password={repeat_password}
              updateNewPassword={updateNewPassword}
              updateRepeatPassword={updateRepeatPassword}
              disabled={disabled}
            />
          </div>

          <div className='panel'>
            <PasswordAdmin
              new_password_admin={new_password_admin}
              repeat_password_admin={repeat_password_admin}
              updateNewPasswordAdmin={updateNewPasswordAdmin}
              updateRepeatPasswordAdmin={updateRepeatPasswordAdmin}
              disabled={disabled}
            />
          </div>

          <hr/>

          <div className='panel'>
            <p className='error-message'>{errorMessage}</p>
            <Button
              text={props.intl.formatMessage({ id: 'save' })}
              click={handleSave}
              bgColor='green'
            />
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

export default EditUser;
