import React from 'react';

import './GridHeader.scss';

export interface IGridHeaderItem {
  name: string;
  value: string;
}

export interface IGridHeader {
  sortable?: boolean;
  sortedBy?: string;
  sortOrder?: boolean;
  onSortChange?: (sort: string) => void;
  headers: IGridHeaderItem[];
}

const GridHeader = (props: IGridHeader ) => {
  const {
    headers,
    sortable,
    sortedBy,
    sortOrder,
    onSortChange,
  } = props;
  const ths = headers.map((header, i) => {
    if (sortable && onSortChange) {
      let classes = '';
      if (sortedBy === header.name) {
        classes = sortOrder ? 'ordered-asc' : 'ordered-desc';
      }
      const sortFn = () => { onSortChange(header.name); };
      return (
        <th
          key={i}
          className={`grid-header ${classes}`}
          onClick={sortFn}
        >
          {header.value}
        </th>
      );
    } else {
      return <th key={i}>{header.value}</th>;
    }
  });

  return (
    <thead>
      <tr>
        {ths}
      </tr>
    </thead>
  );
};

export default GridHeader;
