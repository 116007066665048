import { Action } from 'typescript-fsa';

import ActionTypes from 'constants/ActionTypes/editUser';
import ActionTypesAuth from 'constants/ActionTypes/auth';
import { IEditUser, ISubscriptionsData, IPartner } from 'actions/editUser';
import { IUserType } from 'actions/users';

export interface IEditUserState {
  user: IEditUser;
  original_usertype_id: IUserType;
  subscriptions_data: ISubscriptionsData;
  partners: IPartner[];
  show: boolean;
  loading: boolean;
  error: string;
}

const initialState: IEditUserState = {
  user: {
    id: 0,
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    active: false,
    max_players: 0,
    count_players: 0,
    expiration: '',
    last_login: '',
    music: false,
    client: false,
    new_password: '',
    repeat_password: '',
    new_password_admin: '',
    repeat_password_admin: '',
    usertype_id: IUserType.end_user,
    administrator_id: null,
  },
  original_usertype_id: IUserType.end_user,
  subscriptions_data: {
    max_subscriptions: 0,
    subscriptions_in_use: 0,
    db_host: '',
    db_port: '',
    db_database: '',
    db_username: '',
    db_password: '',
    db_driver: '',
    music_wl: false,
  },
  partners: [],
  show: false,
  loading: false,
  error: '',
};

export const editUser = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.SHOW_USER: {
      const usertype_id = action.payload.usertype_id ? action.payload.usertype_id : 'null';
      return {
        ...state,
        user: {
          ...action.payload,
          new_password: '',
          repeat_password: '',
          new_password_admin: '',
          repeat_password_admin: '',
          active: action.payload.active === 1 ? true : false,
          music: action.payload.music === 1 ? true : false,
          client: action.payload.client === 1 ? true : false,
          expiration: action.payload.trial_ends_at,
          usertype_id,
          administrator_id: action.payload.administrator_id ? action.payload.administrator_id : null,
        },
        original_usertype_id: usertype_id,
        show: true,
        error: '',
      };
    }

    case ActionTypes.SUCCESS_SUBSCRIPTIONS_DATA: {
      const dbData = JSON.parse(action.payload.db);
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          max_subscriptions: action.payload.max_subscriptions,
          subscriptions_data: state.subscriptions_data.subscriptions_in_use,
          db_host: dbData.db_host,
          db_database: dbData.db_database,
          db_username: dbData.db_username,
          db_password: dbData.db_password,
          db_port: dbData.db_port,
          db_driver: dbData.db_driver,
          music_wl: action.payload.music === 1 ? true : false,
        },
      };
    }

    case ActionTypes.CLOSE_USER: {
      return initialState;
    }

    case ActionTypes.UPDATE_FIRST_NAME: {
      return {
        ...state,
        user: {
          ...state.user,
          first_name: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_LAST_NAME: {
      return {
        ...state,
        user: {
          ...state.user,
          last_name: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_COMPANY: {
      return {
        ...state,
        user: {
          ...state.user,
          company: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_EMAIL: {
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_EXPIRATION: {
      return {
        ...state,
        user: {
          ...state.user,
          expiration: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_ACTIVE: {
      return {
        ...state,
        user: {
          ...state.user,
          active: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_MUSIC: {
      return {
        ...state,
        user: {
          ...state.user,
          music: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_CLIENT: {
      return {
        ...state,
        user: {
          ...state.user,
          client: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_MAX_PLAYERS: {
      return {
        ...state,
        user: {
          ...state.user,
          max_players: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_NEW_PASSWORD: {
      return {
        ...state,
        user: {
          ...state.user,
          new_password: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_REPEAT_PASSWORD: {
      return {
        ...state,
        user: {
          ...state.user,
          repeat_password: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_NEW_PASSWORD_ADMIN: {
      return {
        ...state,
        user: {
          ...state.user,
          new_password_admin: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_REPEAT_PASSWORD_ADMIN: {
      return {
        ...state,
        user: {
          ...state.user,
          repeat_password_admin: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_USER_TYPE: {
      return {
        ...state,
        user: {
          ...state.user,
          usertype_id: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_ADMINISTRATOR: {
      return {
        ...state,
        user: {
          ...state.user,
          administrator_id: action.payload,
        },
      };
    }

    case ActionTypes.SUCCESS_PARTNERS: {
      return {
        ...state,
        partners: action.payload,
      };
    }

    case ActionTypes.UPDATE_MAX_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          max_subscriptions: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_DB_HOST: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          db_host: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_DB_PORT: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          db_port: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_DB_DRIVER: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          db_driver: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_DB_DATABASE: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          db_database: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_DB_USERNAME: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          db_username: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_DB_PASSWORD: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          db_password: action.payload,
        },
      };
    }

    case ActionTypes.UPDATE_MUSIC_WL: {
      return {
        ...state,
        subscriptions_data: {
          ...state.subscriptions_data,
          music_wl: action.payload,
        },
      };
    }

    case ActionTypes.REQUEST_EDIT_USER: {
      return {
        ...state,
        loading: true,
      };
    }

    case ActionTypes.SUCCESS_EDIT_USER: {
      return initialState;
    }

    case ActionTypes.FAILURE_EDIT_USER: {
      const error = (action.payload.status < 500) ? action.payload.response.error : 'error-general';
      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ActionTypesAuth.SUCCESS_LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};
