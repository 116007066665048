import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import { auth, IAuthState } from './auth';
import { users, IUsersState } from './users';
import { editUser, IEditUserState } from './editUser';

const dataReducer = combineReducers({
  auth,
  users,
  editUser,
});

interface IDataState {
  auth: IAuthState;
  users: IUsersState;
  editUser: IEditUserState;
}


export interface IState {
  router: any;
  data: IDataState;
}

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  data: dataReducer,
});

export default rootReducer;
