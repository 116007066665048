import { mergeMap } from 'rxjs/operators';
import { ofType, Epic, ActionsObservable, StateObservable } from 'redux-observable';

import ActionTypes from '../constants/ActionTypes/editUser';
import { Observable } from 'rxjs';
import { Action } from 'redux';

import { requestWithAuth } from './utils';
import {
  successEditUser,
  failureEditUser,
  successSubscriptionsData,
  failureSubscriptionsData,
  successPartners,
  failurePartners,
} from '../actions/editUser';
import { IState } from '../reducers';
import { formatEditUser } from '../utils/formatEditUser';
import { IUserType } from 'actions/users';

export const fetchUsers: Epic = (
  action$: ActionsObservable<any>,
  store: StateObservable<IState>
): Observable<Action> => action$.pipe(
  ofType(ActionTypes.REQUEST_EDIT_USER),
  mergeMap(() => {
    const user = store.value.data.editUser.user;
    const subscription_data = store.value.data.editUser.subscriptions_data;
    const body: any = formatEditUser(user, subscription_data);
    if (body.usertype_id === IUserType.end_user) {
      body.usertype_id = null;
    }

    return requestWithAuth({
        action$,
        store,
        method: 'PUT',
        url: `/management/users/${user.id}/update`,
        body,
        successAction: successEditUser,
        successActionParams: user,
        failureAction: failureEditUser,
        noV1: true,
    });
  })
);

export const fetchPartners: Epic = (
  action$: ActionsObservable<any>,
  store: StateObservable<IState>
): Observable<Action> => action$.pipe(
  ofType(ActionTypes.REQUEST_PARTNERS),
  mergeMap(() =>
    requestWithAuth({
      action$,
      store,
      method: 'GET',
      url: '/management/users/partners',
      successAction: successPartners,
      failureAction: failurePartners,
      noV1: true,
    })
  )
);

export const fetchSubscriptionData = (action$: any, store: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_SUBSCRIPTIONS_DATA),
  mergeMap((action: any) =>
    requestWithAuth({
      action$,
      store,
      method: 'GET',
      url: `/management/users/${action.payload}/config`,
      successAction: successSubscriptionsData,
      failureAction: failureSubscriptionsData,
      noV1: true,
    })
  )
);
