import React from 'react';

import './Button.scss';

type IButtonBGColor = 'red' | 'green';
type IButtonSize = 'small';

export interface IButtonProps {
  text: string;
  size?: IButtonSize;
  bgColor?: IButtonBGColor;
  disabled?: boolean;
  extraClass?: string;
  click: () => void;
}

const Button = (props: IButtonProps) => {
  const { bgColor, extraClass, text, size, click } = props;
  const classes = bgColor ? `btn btn-${bgColor}` : 'btn';
  const extraClasses = extraClass ? extraClass : '';
  const sizeClass = size ? size : '';
  const disabled = props.disabled || false;

  return (
    <button className={`${classes} ${extraClasses} ${sizeClass}`} onClick={click} disabled={disabled}>
      {text}
    </button>
  );
};

export default Button;
