import { connect } from 'react-redux';

import UsersGrid from './UsersGrid';
import {
  requestUsers,
  pageChange,
  pageLengthChange,
  columnChange,
} from 'actions/users';

import { IState } from 'reducers';


const mapStateToProps = (state: IState) => ({
  users: state.data.users.users,
  loading: state.data.users.loading,
  page_data:state.data.users.page_data,
  loggedUserType: state.data.auth.usertype_id,
});

const mapDispatchToProps = {
  requestUsers,
  pageChange,
  pageLengthChange,
  columnChange,
  
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersGrid);
