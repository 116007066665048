import React, { useState } from 'react';

import './Dropdown.scss';

export interface IDropdownOption {
  value: any;
  label: string;
}

export interface IDropdownOptionTranslatable extends IDropdownOption {
  id: string;
}

interface IProps {
  label: string;
  options: IDropdownOption[];
  selected: string | number | boolean;
  selectedLabel?: string;
  classes?: string;
  disabled?: boolean;
  onSelect: (value: IDropdownOption) => void;
}

const Dropdown = (props: IProps) => {
  const [visible, setVisible] = useState(false);
  const { selected, label, options, onSelect, disabled, selectedLabel } = props;
  const selectedOption: IDropdownOption =
    options.find((option) => option.value === selected) || { value: 'null', label: selectedLabel ? selectedLabel : 'Elija opción' };

  const chooseOption = (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>, option: IDropdownOption) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(option);
    setVisible(!visible);
  };

  const toggleVisible = () => {
    setVisible(!visible);
  };

  const hide = () => {
    setVisible(false);
  };

  const optionsList = options.map((option, i) =>
    <p key={i} onClick={(e) => chooseOption(e, option)}>{option.label}</p>
  );

  const visibleClass = visible ? 'dropdown-visible' : '';

  const disabledClass = disabled ? 'dropdown-disabled' : '';

  return (
    <div
      className={`dropdown ${visibleClass} ${props.classes} ${disabledClass}`}
      onClick={toggleVisible}
      onMouseLeave={hide}
    >
      <label>{label}</label>
      <span>{selectedOption.label}</span>
      <div className='dropdown-options-container'>
        <div className='dropdown-options'>
          {optionsList}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
