import { IEditUser, ISubscriptionsData } from '../actions/editUser';
import { IUserType } from 'actions/users';

interface ISubscriptionsDataForRequest {
  max_subscriptions: number;
  music?: boolean;
  client?: boolean;
  db?: {
    db_host: string;
    db_database: string;
    db_username: string;
    db_password: string;
    db_port: string;
    db_driver: string;
  };
}

interface IEditUserForRequest {
  active: 0 | 1;
  company: string;
  email: string;
  expirationDate: string;
  firstName: string;
  lastName: string;
  maxPlayers: number;
  music: 0 | 1;
  client: 0 | 1; 
  password: string;
  password_admin: string;
  usertype_id: IUserType;
  administrator_id?: number | null;
  administrator: undefined | ISubscriptionsDataForRequest;
}

export const formatEditUser =
  (user: IEditUser, subscription_data: ISubscriptionsData): IEditUserForRequest => {
  const date = new Date(user.expiration);
  const newExpiration = date.toISOString().split('T')[0] + ' 00:00:00';

  let administrator;
  if (user.usertype_id === IUserType.white_label) {
    administrator = {
      max_subscriptions: subscription_data.max_subscriptions,
      music: subscription_data.music_wl,
      db: {
        db_host: subscription_data.db_host,
        db_port: subscription_data.db_port,
        db_driver: subscription_data.db_driver,
        db_database: subscription_data.db_database,
        db_username: subscription_data.db_username,
        db_password: subscription_data.db_password,
      },
    };
  } else if (user.usertype_id === IUserType.partner) {
    administrator = {
      max_subscriptions: subscription_data.max_subscriptions,
    };
  }

  return {
    active: user.active ? 1 : 0,
    company: user.company,
    email: user.email,
    expirationDate: newExpiration,
    firstName: user.first_name,
    lastName: user.last_name,
    maxPlayers: user.max_players,
    music: user.music ? 1 : 0,
    client: user.client ? 1 : 0,
    password: user.new_password,
    password_admin: user.new_password_admin,
    usertype_id: user.usertype_id,
    administrator,
    administrator_id: user.administrator_id,
  };
};
