import React, { useEffect } from 'react';

import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import './Header.scss';
import { IUsersState } from 'reducers/users';
import { ILang } from 'reducers/auth';
import languages from 'lang/languages';
import { IntlFormatters } from 'react-intl';
import { IUserType } from 'actions/users';
import Logo from 'assets/img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

interface IProps {
  intl: IntlFormatters;
  lang: ILang;
  usersState: IUsersState;
  max_suscriptions: number;
  suscriptions_in_use: number;
  usertype_id: IUserType;
  invalid_subscriptions_number: boolean;
  email: string;
  requestLogout: () => void;
  requestProfile: () => void;
  setLang: (val: ILang) => void;
  goToHome: () => void;
  goToUsers: () => void;
}

const LOGOUT_ACTION = 'logout';

const Header = (props: IProps) => {
  const { requestProfile, invalid_subscriptions_number, email } = props;
  const {
    goToHome,
    goToUsers,
  } = props;

  useEffect(() => {
    requestProfile();
  }, [requestProfile]);


 const handleLanguageChange = (option : ILang) => {
   
   props.setLang(option);
 }

 const handleLogoutChange = () => {
   
  props.requestLogout();
}

  const invalidClass = invalid_subscriptions_number ? 'invalid' : '';

  const suscriptions_info = (
    <span className={`suscriptions-data ${invalidClass}`}>
      {props.intl.formatMessage({id: 'suscriptions'})}: {props.suscriptions_in_use} / {props.max_suscriptions}
    </span>
  );
  
  const languageOptions = [
    ...languages,
  ];
  
  const language = languages.find( lang => lang.value === props.lang );
  const lng = (language!=null)?language.label:'';
  const configOptions = [];
  configOptions.push({
    value: LOGOUT_ACTION,
    label: props.intl.formatMessage({ id: 'logout' }),
  });

  
  return (
    <div className='Header'>


      <Navbar bg="light" variant="light">
        <Navbar.Brand onClick={goToHome}><div>
                  <img src={Logo} alt='Argentina' height='50' />
                </div></Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link onClick={goToHome}>{props.intl.formatMessage({id: 'home'})}</Nav.Link>
          <Nav.Link onClick={goToUsers}>{props.intl.formatMessage({id: 'users'})}</Nav.Link>
        </Nav>
        <Nav>
          {suscriptions_info}
        </Nav>
        

        <NavDropdown title={lng} id="basic-nav-dropdown">
          {languageOptions.map((lng) => (
            <NavDropdown.Item key={lng.value} value={lng.value} eventKey={lng.value} 
              onSelect={() => handleLanguageChange(lng.value as ILang)}
            >{lng.label}</NavDropdown.Item>
          ))}
        </NavDropdown>
        <NavDropdown title={email} id="basic-nav-dropdown-logout">
          <NavDropdown.Item
            onSelect={() => handleLogoutChange()}
          >{props.intl.formatMessage({ id: 'logout' })}</NavDropdown.Item>

        </NavDropdown>
      </Navbar>
  
    </div> 
  );
};

export default Header;
