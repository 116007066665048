import { IEditUser } from 'actions/editUser';

interface IValidUser {
  valid: boolean;
  errors: string[];
}

export const validateUser = (user: IEditUser): IValidUser => {
  let valid: boolean = true;
  const errors: string[] = [];
  if (
    (user.new_password && !user.repeat_password) ||
    (!user.new_password && user.repeat_password)
  ) {
    valid = false;
    errors.push('Debe ingresar ambas contraseñas');
  } else {
    if (user.new_password !== user.repeat_password) {
      valid = false;
      errors.push('Las contraseñas deben coincidir');
    }
  }

  if (
    (user.new_password_admin && !user.repeat_password_admin) ||
    (!user.new_password_admin && user.repeat_password_admin)
  ) {
    valid = false;
    errors.push('Debe ingresar ambas contraseñas de Administrador');
  } else {
    if (user.new_password_admin !== user.repeat_password_admin) {
      valid = false;
      errors.push('Las contraseñas de Administrador deben coincidir');
    }
  }

  return {
    valid,
    errors,
  };
};
