import actionCreatorFactory from 'typescript-fsa';
import ActionTypes from 'constants/ActionTypes/editUser';
import { IUserType } from './users';

const actionCreator = actionCreatorFactory();

export type IAdministratorId = number | null;

export interface IEditUser {
  id: number;
  max_players: number;
  active: boolean;
  email: string;
  last_name: string;
  first_name: string;
  company: string;
  count_players: number;
  expiration: string;
  last_login: string;
  music: boolean;
  client: boolean;
  new_password: string;
  repeat_password: string;
  new_password_admin: string;
  repeat_password_admin: string;
  usertype_id: IUserType;
  administrator_id: number | null;
}

export interface ISubscriptionsData {
  max_subscriptions: number;
  subscriptions_in_use: number;
  db_host: string;
  db_port: string;
  db_database: string;
  db_username: string;
  db_password: string;
  db_driver: string;
  music_wl: boolean;
}

export interface IPartner {
  id: number;
  first_name: string;
  last_name: string;
}

export const showUser = actionCreator<IEditUser>(ActionTypes.SHOW_USER);
export const closeUser = actionCreator<undefined>(ActionTypes.CLOSE_USER);

export const requestEditUser = actionCreator<undefined>(ActionTypes.REQUEST_EDIT_USER);
export const successEditUser = actionCreator<IEditUser>(ActionTypes.SUCCESS_EDIT_USER);
export const failureEditUser = actionCreator<undefined>(ActionTypes.FAILURE_EDIT_USER);

export const requestPartners = actionCreator<undefined>(ActionTypes.REQUEST_PARTNERS);
export const successPartners = actionCreator<IPartner[]>(ActionTypes.SUCCESS_PARTNERS);
export const failurePartners = actionCreator<undefined>(ActionTypes.FAILURE_PARTNERS);

export const requestSubscriptionsData = actionCreator<number>(ActionTypes.REQUEST_SUBSCRIPTIONS_DATA);
export const successSubscriptionsData =
  actionCreator<ISubscriptionsData>(ActionTypes.SUCCESS_SUBSCRIPTIONS_DATA);
export const failureSubscriptionsData = actionCreator<undefined>(ActionTypes.FAILURE_SUBSCRIPTIONS_DATA);

export const updateFirstName = actionCreator<string>(ActionTypes.UPDATE_FIRST_NAME);
export const updateLastName = actionCreator<string>(ActionTypes.UPDATE_LAST_NAME);
export const updateCompany = actionCreator<string>(ActionTypes.UPDATE_COMPANY);
export const updateEmail = actionCreator<string>(ActionTypes.UPDATE_EMAIL);
export const updateExpiration = actionCreator<string>(ActionTypes.UPDATE_EXPIRATION);
export const updateMaxPlayers = actionCreator<number>(ActionTypes.UPDATE_MAX_PLAYERS);
export const updateActive = actionCreator<boolean>(ActionTypes.UPDATE_ACTIVE);
export const updateMusic = actionCreator<boolean>(ActionTypes.UPDATE_MUSIC);
export const updateClient = actionCreator<boolean>(ActionTypes.UPDATE_CLIENT);
export const updateNewPassword = actionCreator<string>(ActionTypes.UPDATE_NEW_PASSWORD);
export const updateRepeatPassword = actionCreator<string>(ActionTypes.UPDATE_REPEAT_PASSWORD);
export const updateNewPasswordAdmin = actionCreator<string>(ActionTypes.UPDATE_NEW_PASSWORD_ADMIN);
export const updateRepeatPasswordAdmin = actionCreator<string>(ActionTypes.UPDATE_REPEAT_PASSWORD_ADMIN);
export const updateUserType = actionCreator<IUserType>(ActionTypes.UPDATE_USER_TYPE);
export const updateAdministrator = actionCreator<IAdministratorId>(ActionTypes.UPDATE_ADMINISTRATOR);

export const updateMaxSubscriptions = actionCreator<number>(ActionTypes.UPDATE_MAX_SUBSCRIPTIONS);
export const updateDBHost = actionCreator<number>(ActionTypes.UPDATE_DB_HOST);
export const updateDBDatabase = actionCreator<number>(ActionTypes.UPDATE_DB_DATABASE);
export const updateDBPort = actionCreator<number>(ActionTypes.UPDATE_DB_PORT);
export const updateDBDriver = actionCreator<number>(ActionTypes.UPDATE_DB_DRIVER);
export const updateDBUsername = actionCreator<number>(ActionTypes.UPDATE_DB_USERNAME);
export const updateDBPassword = actionCreator<number>(ActionTypes.UPDATE_DB_PASSWORD);
export const updateMusicWL = actionCreator<boolean>(ActionTypes.UPDATE_MUSIC_WL);
