import React from 'react';
import Grid from 'components/Grid';
import { headers } from './headers';
import { pageLengthOptions } from 'constants/pageOptions';
import { IUserList, IUserType } from 'actions/users';
import Loading from 'components/Loading';
import userTypeLabel from 'utils/userTypeLabel';
import {IUsersPageData} from 'reducers/users';
import { IntlFormatters } from 'react-intl';


export interface IUser {
  id: number;
  name: string;
  apellido: string;
  empresa: string;
  email: string;
  max_players: string;
  count_players: string;
  last_login: string;
  trial_ends_at: string;
  client: string;
  user_type: string;
}

interface IProps {
  users: IUserList[];
  loading: boolean;
  page_data: IUsersPageData;
  requestUsers: () => void;
  pageChange: (i: number) => void;
  pageLengthChange: (i: number) => void;
  columnChange: (i: string) => void;
  showUser: (user: IUserList) => void;
  intl: IntlFormatters;
  loggedUserType: IUserType;
}

interface IState {
  sortOrder: boolean;
  search: string;
}


class UsersGrid extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
        
    this.state = {
      sortOrder: true,
      search: '',
    };

    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    
  }

  componentDidMount() {
    
    this.props.requestUsers();
  }

  handleSearchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  handleSortChange(sortField: string) {
    
    this.props.columnChange(sortField);
    this.props.requestUsers();
  }

  

  handlePageChange(selectedItem: { selected: number }) {
    this.props.pageChange(+selectedItem.selected + 1);
    this.props.requestUsers();
  }

  render() {
    const usersList = this.props.users.map((item: IUserList) => {
      
      const toggleUser = (user: IUserList) => {
        this.props.showUser(user);
      };
      const status = item.active ? 'Si' : "No";
      const client = item.client ? 'Si' : "No";
      const trial_end  = item.trial_ends_at ? item.trial_ends_at.substring(0, 10) : '';
      return {
        content: [
          status,
          item.first_name,
          item.last_name,
          item.company,
          item.email,
          item.max_players.toString(),
          item.count_players.toString(),
          item.last_login,
          trial_end,
          client,
          userTypeLabel(item.usertype_id),
        ],
        actions: [
          {
            name: 'Ver',
            color: 'success',
            icon: 'fa-search',
            onClick: () => { toggleUser(item) }
            
          },
        ],
      };
    });

    const loading = this.props.loading ? <Loading /> : null;

    const translatedHeaders = headers.map((cell) => {
      cell.value = this.props.intl.formatMessage({ id: cell.id });
      return cell;
    }).filter((cell) => {
      return (cell.id !== 'col-type') || (cell.id === 'col-type' && this.props.loggedUserType === IUserType.root);
    });

    return (
      <div>
        <div className='usergrid'>
          {loading}
          <Grid
            sortable={true}
            sortOrder={this.state.sortOrder}
            sortedBy='name'
            onSortChange={this.handleSortChange}
            paginate={true}
            pageLength={1}
            pageLengthOptions={pageLengthOptions}
            onPageChange={this.handlePageChange}
            filterable={true}
            onSearchChange={this.handleSearchChange}
            searchValue={this.state.search}
            headers={translatedHeaders}
            items={usersList}
            totalPages= {this.props.page_data.last_page}

          />

        </div>
      </div>
    );
  }
}

export default UsersGrid;
