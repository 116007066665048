import React from 'react';
import { IntlFormatters } from 'react-intl';

interface IProps {
  intl: IntlFormatters;
  new_password: string;
  repeat_password: string;
  disabled?: boolean;
  updateNewPassword: (val: string) => void;
  updateRepeatPassword: (val: string) => void;
}
const Password = (props: IProps) => {
  const {
    new_password,
    repeat_password,
    updateNewPassword,
    updateRepeatPassword,
    disabled,
  } = props;

  const handleNewPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateNewPassword(e.currentTarget.value);
  };

  const handleRepeatPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateRepeatPassword(e.currentTarget.value);
  };

  return (
    <React.Fragment>
      <div className='panel-header'>
        <h4>{props.intl.formatMessage({ id: 'password-config' })}</h4>
      </div>

      <div className='panel-body'>
        <div className='panel-option'>
          <label>{props.intl.formatMessage({ id: 'new-password' })}</label>
          <input
            name='new_password'
            type='text'
            value={new_password}
            onChange={handleNewPasswordChange}
            disabled={disabled}
          />
        </div>

        <div className='panel-option'>
          <label>{props.intl.formatMessage({ id: 'repeat-new-password' })}</label>
          <input
            name='repeat_new_password'
            type='text'
            value={repeat_password}
            onChange={handleRepeatPasswordChange}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Password;