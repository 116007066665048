//import React, { useState, useEffect } from 'react';
import React from 'react';
// import SimpleBar from 'simplebar-react';

import Header from 'components/Header';
// import Loading from 'components/Loading';

// import GridHeader from 'components/GridHeader';
// import headers from './headers';
// import { IUsersState } from 'reducers/users';
// import { IUserList, IUserType } from 'actions/users';
// import parseUser, { filterUsersStatus } from './parseUserList';
// import Row from 'components/Row';
// import EditUser from 'containers/EditUser';
//import { IntlFormatters } from 'react-intl';

//import './Home.scss';

// interface IProps {
//   intl: IntlFormatters;
//   loading: boolean;
//   usersState: IUsersState;
//   showEditUser: boolean;
//   selectedId: number;
//   loggedUserType: IUserType;
//   requestUsers: () => void;
//   showUser: (user: IUserList) => void;
// }



//const Home = (props: IProps) => {
  //let choicesUsers: any[] = [];
  //const [contentBody, setContentBody] = useState<any[]>(choicesUsers);
  // const {
  //   requestUsers,
  //   showEditUser,
  //   selectedId,
  //   showUser,
  //   loggedUserType,
  // } = props;
  // const {
  //   loading,
  //   users,
  //   filter,
  // } = props.usersState;

  // useEffect(() => {
  //   requestUsers();
  // }, [requestUsers]);

  // useEffect(() => {
  //   const toggleUser = (user: IUserList) => {
  //     showUser(user);
  //   };
  //   setContentBody(filterUsersStatus(users, filter.text, filter.status, filter.client)
  //   .map((user: IUserList) => {
  //     const classes = selectedId === user.id ? 'user row-selected' : 'user';
  //     return (
  //       <Row
  //         key={user.id}
  //         columns={parseUser(user, loggedUserType)}
  //         classes={classes}
  //         onClick={() => { toggleUser(user) }}
  //       />
  //     );
  //   }));
  // }, [users, filter.text, filter.status, filter.client, selectedId, showUser, loggedUserType]);

  // const content = (!loading)
  //   ? (
  //     <SimpleBar style={{ height: '100%' }}>
  //       {contentBody}
  //     </SimpleBar>
  //   ) : <Loading />;

  // const editUser = showEditUser ? (
  //   <div className='edit-user-container'>
  //     <EditUser />
  //   </div>
  // ) : null;

  // const translatedHeaders = headers.map((cell) => {
  //   cell.content = props.intl.formatMessage({ id: cell.id });
  //   return cell;
  // }).filter((cell) => {
  //   return (cell.id !== 'col-type') || (cell.id === 'col-type' && loggedUserType === IUserType.root);
  // });

  const Home = () => {
  return (
    <React.Fragment>
      <Header />
      {/* <section className='home'>
        <div className='users-list'>
          <GridHeader columns={translatedHeaders} />
          {content}
        </div>
        {editUser}
      </section> */}
      <section className='home'>
        <div className='center'>
          Management
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;
