import { IUserType } from 'actions/users';
import userTypeLabel from 'utils/userTypeLabel';

export interface IUserTypeOption {
  value: IUserType;
  label: string;
  id: string;
}

const userTypesOptions: IUserTypeOption[] = [
  {
    value: IUserType.end_user,
    label: userTypeLabel(IUserType.end_user),
    id: 'user-type-end',
  },
  {
    value: IUserType.root,
    label: userTypeLabel(IUserType.root),
    id: 'user-type-root',
  },
  {
    value: IUserType.white_label,
    label: userTypeLabel(IUserType.white_label),
    id: 'user-type-white-label',
  } ,
  {
    value: IUserType.partner,
    label: userTypeLabel(IUserType.partner),
    id: 'user-type-partner',
  }
];

export default userTypesOptions;
