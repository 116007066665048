function strEnum<T extends string>(o: T[]): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export default strEnum([
  'REQUEST_USERS',
  'REQUEST_NEW_USER',
  'SUCCESS_USERS',
  'FAILURE_USERS',

  'FILTER_USERS_TEXT',
  'FILTER_USERS_STATUS',
  'FILTER_USERS_CLIENT',

  'USERS_PAGE_LENGTH_CHANGE',
  'USERS_PAGE_CHANGE',
  'USERS_COLUMN_CHANGE',
]);
