import React from 'react';

import { connect } from 'react-redux';

import Login from 'containers/Login';
import { IState } from 'reducers';

interface IWithLoginProps {
  access_token: string;
  refresh_token: string;
}

export default function withLogin() {
  return (WrappedComponent: React.ComponentType) => {
    class WithLogin extends React.Component<IWithLoginProps> {
      render() {
        if (this.props.access_token && this.props.refresh_token) {
          return <WrappedComponent {...this.props} />;
        }

        return <Login />;
      }
    }

    const mapStateToProps = (state: IState) => ({
      access_token: state.data.auth.access_token,
      refresh_token: state.data.auth.refresh_token,
    });

    return connect(mapStateToProps)(WithLogin);
  };
}
