export const headers = [
  {
    name: 'user_groups.active',
    value: 'E',
    sortable: true,
    id: 'col-status',
  },
  {
    name: 'users.first_name',
    value: 'Nombre',
    sortable: true,
    id: 'col-name',
  },
  {
    name: 'users.last_name',
    value: 'Apellido',
    sortable: true,
    id: 'col-last-name',
  },
  {
    
    value: 'Empresa',
    sortable: true,
    name: 'user_groups.name',
    id: 'col-company',
  },
  {
    
    value: 'Email',
    sortable: true,
    name: 'users.email',
    id: 'col-email',
  },
  {
    
    value: 'Máx.',
    sortable: true,
    name: 'user_groups.max_players',
    id: 'col-max-players',
  },
  {
    
    value: 'En uso',
    sortable: true,
    name: 'count_players',
    id: 'col-players-in-use',
  },
  {
    
    value: 'Ultimo login',
    sortable: true,
    name: 'users.last_login',
    id: 'col-last-login',
  },
  {
    
    value: 'Expiración',
    sortable: true,
    name: 'user_groups.trial_ends_at',
    id: 'col-expiration',
  },
  {
    
    value: 'Cliente',
    sortable: true,
    name: 'user_groups.client',
    id: 'col-client',
  },
  {
    
    value: 'Tipo',
    sortable: true,
    name: 'users.usertype_id',
    id: 'col-type',
  },
  
];


