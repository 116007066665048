import { Action } from 'typescript-fsa';

import ActionTypes from 'constants/ActionTypes/users';
import ActionTypesEditUser from 'constants/ActionTypes/editUser';
import { IUserList, IUsersStatusFilter, IUsersClientFilter, IUserType } from 'actions/users';

export interface IUsersPageData {
  page_number: number;
  items_page: number;
  column: string;
  order: boolean;
  last_page: number;
}

export interface IUsersPageData {
  page_number: number;
  items_page: number;
  column: string;
  order: boolean;
  last_page: number;
  
}

export interface IUsersState {
  page_data: IUsersPageData;
  users: IUserList[];
  filter: {
    text: '',
    status: IUsersStatusFilter,
    client: IUsersClientFilter;
  };
  loading: boolean;
  error: string;
}

const initialState: IUsersState = {
  page_data: {
    page_number: 1,
    items_page: 30,
    column: 'users.id',
    order: false,
    last_page: 1,
  
  },
  users: [],
  filter: {
    text: '',
    status: IUsersStatusFilter.all,
    client: IUsersClientFilter.all,
  },
  loading: false,
  error: '',
};

export const users = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case ActionTypes.REQUEST_USERS: {
      return {
        ...state,
        loading: true,
      };
    }
    
    case ActionTypes.REQUEST_NEW_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    

    case ActionTypes.SUCCESS_USERS: {
      const usersList = action.payload.data.map((user: any) => {
        if (user.usertype_id === null) {
          user.usertype_id = IUserType.end_user;
        }
        return user;
      });
      
      return {
        users: usersList,
        //users: action.payload.data,
        page_data: {
          ...state.page_data,
          last_page: action.payload.last_page,
        },
        filter: {
          ...state.filter,
        },
        loading: false,
        error: '',
      };
    }

    case ActionTypes.FAILURE_USERS: {
      return {
        users: [],
        page_data: {
          ...state.page_data,
        },
        filter: {
          ...state.filter,
        },
        loading: false,
        error: 'Error al obtener los usuarios',
      };
    }

    case ActionTypes.FILTER_USERS_TEXT: {
      return {
        ...state,
        filter: {
          ...state.filter,
          text: action.payload,
        },
      };
    }

    case ActionTypes.FILTER_USERS_STATUS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.payload,
        },
      };
    }

    case ActionTypes.FILTER_USERS_CLIENT: {
      return {
        ...state,
        filter: {
          ...state.filter,
          client: action.payload,
        },
      };
    }

    case ActionTypesEditUser.SUCCESS_EDIT_USER: {
      const oldUser = state.users.find((user) => user.id === action.payload.id);
      if (!oldUser) {
        return state;
      }

      const { payload } = action;

      const date = new Date(payload.expiration);
      const newExpiration = date.toISOString().split('T')[0];

      const updatedUser: IUserList = {
        ...oldUser,
        active: (payload.active === true) ? 1 : 0,
        first_name: payload.first_name,
        last_name: payload.last_name,
        company: payload.company,
        email: payload.email,
        max_players: payload.max_players,
        trial_ends_at: newExpiration,
        music: payload.music ? 1 : 0,
        client: payload.client ? 1 : 0,
        usertype_id: payload.usertype_id,
      };

      const newUsers = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return updatedUser;
        } else {
          return user;
        }
      });

      return {
        ...state,
        users: newUsers,
      };
    }

    case ActionTypes.USERS_COLUMN_CHANGE: {
      
      const column = action.payload;
      const order =
        column === state.page_data.column ? !state.page_data.order : false;

      return {
        ...state,
        page_data: {
          ...state.page_data,
          column,
          order,
        },
      };

    }

    case ActionTypes.USERS_PAGE_CHANGE: {
      return {
        ...state,
        page_data: {
          ...state.page_data,
          page_number: action.payload,
        },
      };
    }

    case ActionTypes.USERS_PAGE_LENGTH_CHANGE: {
      return {
        ...state,
        page_data: {
          ...state.page_data,
          items_page: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
