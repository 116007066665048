import actionCreatorFactory from 'typescript-fsa';
import ActionTypes from 'constants/ActionTypes/auth';
import { ErrorObserver } from 'rxjs';
import { ILang } from 'reducers/auth';

const actionCreator = actionCreatorFactory();

export interface ILoginRequest {
  grant_type: string;
  username: string;
  password: string;
  client_id: string;
  client_secret: string;
}

export interface ILoginResponse {
  access_token: string;
  refresh_token: string;
}


export const requestAccessToken = actionCreator<ILoginRequest>(ActionTypes.REQUEST_ACCESS_TOKEN);
export const successAccessToken = actionCreator<ILoginResponse>(ActionTypes.SUCCESS_ACCESS_TOKEN);
export const failureAccessToken = actionCreator<ErrorObserver<any>>(ActionTypes.FAILURE_ACCESS_TOKEN);

export const requestRefreshToken = actionCreator<undefined>(ActionTypes.REQUEST_REFRESH_TOKEN);
export const successRefreshToken = actionCreator<ILoginResponse>(ActionTypes.SUCCESS_REFRESH_TOKEN);
export const failureRefreshToken = actionCreator<ErrorObserver<any>>(ActionTypes.FAILURE_REFRESH_TOKEN);

export const requestProfile = actionCreator<undefined>(ActionTypes.REQUEST_PROFILE);
export const successProfile = actionCreator<ILoginResponse>(ActionTypes.SUCCESS_PROFILE);
export const failureProfile = actionCreator<ErrorObserver<any>>(ActionTypes.FAILURE_PROFILE);

export const requestLogout = actionCreator<ErrorObserver<any>>(ActionTypes.REQUEST_LOGOUT);
export const successLogout = actionCreator<ErrorObserver<any>>(ActionTypes.SUCCESS_LOGOUT);
export const failureLogout = actionCreator<ErrorObserver<any>>(ActionTypes.FAILURE_LOGOUT);

export const setLang = actionCreator<ILang>(ActionTypes.SET_LANG);
