import React from 'react';
import UsersGrid from './UsersGrid';
import EditUser from 'containers/EditUser';
import { IUsersState } from 'reducers/users';
import { IUserList, IUserType } from 'actions/users';
import { IntlFormatters } from 'react-intl';
import Header from 'components/Header';
import Filter from './UsersGrid/Filter';
import './Users.scss';
import Button from 'components/Button';

interface IProps {
  users: IUserList[];
  intl: IntlFormatters;
  loading: boolean;
  usersState: IUsersState;
  showEditUser: boolean;
  selectedId: number;
  loggedUserType: IUserType;
  showUser: (user: IUserList) => void;
  requestNewUser: () => void;
  goToNewUser: () => void;
  user_id: number;
}

//class Users extends React.Component<{}, {}> {
const Users = (props: IProps) => {

  const {
    showEditUser,
    //requestNewUser,
    //goToNewUser,
  } = props;

  const editUser = showEditUser ? (
    <div className='edit-user-container'>
      <EditUser />
    </div>
  ) : null;
  
  const handleNewUser = () => {
    //requestNewUser();
    //goToNewUser();
    //window.location.assign("https://www.nuvelar.com/public/signup")
    
    var a = document.createElement('a');
    a.href = "https://www.nuvelar.com/public/signup";//+props.user_id;
    a.setAttribute('target', '_blank');
    a.click();
  };
  
    return (
      <React.Fragment>
        <Header />
        <section className='users-container'>
          <div className='users-list'>
            <h3>{props.intl.formatMessage({ id: 'users' })}</h3>
            
            <Button
              text={props.intl.formatMessage({ id: 'new-user' })}
              click={handleNewUser}
              bgColor='green'
              size='small'
            />
            <Filter 
              intl = {props.intl}
              usersState = {props.usersState}
            />
            <UsersGrid 
              intl = {props.intl}
              showUser = {props.showUser}
            />
          </div>
          {editUser}
        </section>
      </React.Fragment>

     
    );
  
}

export default Users;