import React from 'react';
import ReactPaginate from 'react-paginate';
import './GridPaginate.scss';

interface IProps {
  currentPage: number;
  pageLength: number;
  totalItems: number;
  totalPages: number;
  onPageChange: (selectedItem: { selected: number; }) => void;
}

const GridPaginate = (props: IProps) => {
  const {
    totalPages,
    onPageChange,
  } = props;
  return (
    <ReactPaginate
      previousLabel={'Anterior'}
      nextLabel={'Siguiente'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={'pagination'}
      activeClassName={'active'}
    />
  );
};

export default GridPaginate;
