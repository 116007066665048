import React from 'react';
import { IntlFormatters } from 'react-intl';
import { flow } from 'lodash';
import { injectIntl } from 'react-intl';

interface IProps {
  intl: IntlFormatters;
  new_password_admin: string;
  repeat_password_admin: string;
  disabled?: boolean;
  updateNewPasswordAdmin: (val: string) => void;
  updateRepeatPasswordAdmin: (val: string) => void;
}
const PasswordAdmin = (props: IProps) => {
  const {
    new_password_admin,
    repeat_password_admin,
    updateNewPasswordAdmin,
    updateRepeatPasswordAdmin,
    disabled,
  } = props;

  const handleNewPasswordAdminChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateNewPasswordAdmin(e.currentTarget.value);
  };

  const handleRepeatPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    updateRepeatPasswordAdmin(e.currentTarget.value);
  };

  return (
    <React.Fragment>
      <div className='panel-header'>
        <h4>{props.intl.formatMessage({ id: 'password-admin' })}</h4>
      </div>

      <div className='panel-body'>
        <div className='panel-option'>
          <label>{props.intl.formatMessage({ id: 'new-password-admin' })}</label>
          <input
            name='new_password_admin'
            type='text'
            value={new_password_admin}
            onChange={handleNewPasswordAdminChange}
            disabled={disabled}
          />
        </div>

        <div className='panel-option'>
          <label>{props.intl.formatMessage({ id: 'repeat-new-password-admin' })}</label>
          <input
            name='repeat_new_password_admin'
            type='text'
            value={repeat_password_admin}
            onChange={handleRepeatPasswordChange}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

//export default PasswordAdmin;
export default flow([injectIntl])(PasswordAdmin);