import { Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { IState } from 'reducers';
import { Action } from 'redux';
import { Observable } from 'rxjs';

import ActionTypes from '../constants/ActionTypes/users';
import { mergeMap } from 'rxjs/operators';
import { requestWithAuth, requestCms } from './utils';
import { failureUsers, successUsers } from 'actions/users';

export const fetchUsers: Epic = (
  action$: ActionsObservable<any>,
  store: StateObservable<IState>
): Observable<Action> => action$.pipe(
  ofType(ActionTypes.REQUEST_USERS),
  mergeMap(() => {
      const pageDataStore = store.value.data.users.page_data;

      const page_data = {
        cant: pageDataStore.items_page,
        columna: pageDataStore.column,
        orden: pageDataStore.order ? 'asc' : 'desc',
        pag: pageDataStore.page_number,
        activo: store.value.data.users.filter.status,
        cliente: store.value.data.users.filter.client,
        busqueda: store.value.data.users.filter.text,
        id_user: store.value.data.auth.id,
      };

      return requestWithAuth({
        action$,
        store,
        method: 'POST',
        url: '/management/users/search',
        body: page_data,
        successAction: successUsers,
        failureAction: failureUsers,
      });
    }
  )
);

export const fetchNewUser = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_NEW_USER),
  mergeMap((action: any) =>
  requestCms({
      method: 'POST',
      url: '/signup',
      body: action.payload,
    })
  )
);




