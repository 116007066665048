import { connect } from 'react-redux';
import { flow } from 'lodash';

import Filter from './Filter';

import {
  filterUsersText,
  filterUsersStatus,
  filterUsersClient,
  requestUsers,
} from 'actions/users';
import { IState } from 'reducers';
import { injectIntl } from 'react-intl';

const mapStateToProps = (state: IState) => ({
  usersState: state.data.users,
});

const mapDispatchToProps = {
  filterUsersText,
  filterUsersStatus,
  filterUsersClient,
  requestUsers,
};

const connectDecorator = connect(mapStateToProps, mapDispatchToProps);

export default flow([
  injectIntl,
  connectDecorator,
])(Filter);
