import { mergeMap, map, catchError } from 'rxjs/operators';
import { ofType, StateObservable, ActionsObservable, Epic } from 'redux-observable';
import { of } from 'rxjs';
import { push } from 'connected-react-router';

import ActionTypes from '../constants/ActionTypes/auth';
import ActionTypesEditUser from '../constants/ActionTypes/editUser';
import * as Routes from '../constants/routes';
import { request, requestWithAuth } from './utils';
import { IState } from '../reducers';
import { Action } from 'redux';
import { client_id, client_secret } from '../constants/auth';
import {
  successAccessToken,
  failureAccessToken,
  successLogout,
  failureLogout,
  successRefreshToken,
  successProfile,
  failureProfile
} from '../actions/auth';

export const login = (action$: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_ACCESS_TOKEN),
  mergeMap((action: any) =>
    request({
      method: 'POST',
      url: '/oauth/token',
      body: action.payload,
      noV1: true,
    })
    .pipe(
      map((response) => successAccessToken(response.response)),
      catchError((error) => of(failureAccessToken(error)))
    )
  )
);

export const success_login = (action$: any) => action$.pipe(
  ofType(ActionTypes.SUCCESS_ACCESS_TOKEN),
  mergeMap(() => {
    return of(push(Routes.home()));
  }),
);

export const logout = (action$: any, store: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_LOGOUT),
  mergeMap(() =>
    requestWithAuth({
      action$,
      store,
      method: 'GET',
      url: '/oauth/logout',
      successAction: successLogout,
      failureAction: failureLogout,
      noV1: true,
    })
  )
);

export const me = (action$: any, store: any) => action$.pipe(
  ofType(ActionTypes.REQUEST_PROFILE, ActionTypesEditUser.SUCCESS_EDIT_USER),
  mergeMap(() =>
    requestWithAuth({
      action$,
      store,
      method: 'GET',
      url: '/oauth/users/me',
      successAction: successProfile,
      failureAction: failureProfile,
      noV1: true,
    })
  )
);

export const success_logout = (action$: any) => action$.pipe(
  ofType(ActionTypes.SUCCESS_LOGOUT),
  mergeMap(() => {
    return of(push(Routes.login()));
  }),
);

export const failure_logout = (action$: any) => action$.pipe(
  ofType(ActionTypes.FAILURE_LOGOUT),
  mergeMap(() => {
    return of(push(Routes.login()));
  }),
);

export const failRefreshToken = (action$: any) => action$.pipe(
  ofType(ActionTypes.FAILURE_REFRESH_TOKEN),
  mergeMap(() => {
    return of(push(Routes.login()));
  }),
);

export const getNewAccessToken: Epic = (
  action$: ActionsObservable<Action>,
  store: StateObservable<IState>
) =>
  action$.pipe(
    ofType(ActionTypes.REQUEST_REFRESH_TOKEN),
    mergeMap(() => {
      const { auth } = store.value.data;
      const data = {
        access_token: auth.access_token,
        refresh_token: auth.refresh_token,
        client_id,
        client_secret,
        grant_type: 'refresh_token',
      };
      return request({
        method: 'POST',
        url: '/oauth/token',
        body: data,
        noV1: true,
      })
        .pipe(
          map((response) => successRefreshToken(response.response)),
          catchError((error) => of(failureAccessToken(error)))
        );
    }),
  );
