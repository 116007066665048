const languages = [
  {
    value: 'es',
    label: 'Español',
  },
  {
    value: 'en',
    label: 'English',
  },
];

export default languages;
