import { flow } from 'lodash';
import { connect } from 'react-redux';

import Users from './Users';
import withLogin from 'containers/withLogin/withLogin';
import {
  showUser,
} from 'actions/editUser';
import {
  requestUsers,
  requestNewUser,
} from 'actions/users';
import { IState } from 'reducers';
import { injectIntl } from 'react-intl';
import {
    goToNewUser,
} from 'actions/routes';

const mapStateToProps = (state: IState) => ({
  usersState: state.data.users,
  showEditUser: state.data.editUser.show,
  selectedId: state.data.editUser.user.id,
  loggedUserType: state.data.auth.usertype_id,
  user_id: state.data.auth.id,
});

const mapDispatchToProps = {
  requestUsers,
  requestNewUser,
  showUser,
  goToNewUser,
};

const connectDecorator = connect(mapStateToProps, mapDispatchToProps);

export default flow([
  withLogin(),
  connectDecorator,
  injectIntl,
])(Users);

